<template>
  <div class="energyReport">
    <div id="maintablepage">
      <div class="maintablepage-content">
        <button @click="handleExport">生成PDF</button>
        <!-- 内容 -->
        <div class="contant_O pdf-panel" id="class_pdf">
          <headTitle :objAll="objAll"></headTitle>
          <directory :objAll="objAll" :nameList="nameList"></directory>
          <div class="maintablepage-header">
            <div class="maintablepage-header-name">能源报告</div>
          </div>
          <!-- 1.1. 总成本及收益分析 -->
          <div class="cont_z">
            <div class="msrStr">1. 总成本及收益分析</div>
            <el-table class="app-table" :data="tableData" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="" align="left" prop="name">
              </el-table-column>
              <el-table-column label="本期" align="center" prop="codeValue">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="type">
                <template slot-scope="scope">
                  <div style="display: flex;justify-content: center;">
                    <!-- 升 -->
                    <!-- <i class="el-icon-caret-top" style="color: red;font-size: 24px;line-height: 40px;"></i> -->
                    {{ scope.row.type }}
                    <!-- 降 -->
                    <!-- <i class="el-icon-caret-bottom" style="color: green;font-size: 24px;line-height: 40px;"></i> -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="同级别排名" align="center" prop="site">
              </el-table-column>
            </el-table>
            <div class="marStyle">能源成本共 {{ (Number(chartData.cost) + Number(chartData.avgPowerFactor) +
              Number(chartData.baseCost)).toFixed(2) }}元，其构成如下：</div>
            <div style="width: 70%;height: 300px;margin: auto;" ref="chart1" id="chart1"></div>
            <div class="marStyle">综合能源收益共 {{ (sumNaN(selfData.self) + sumNaN(selfData.storage) +
              sumNaN(selfData.surplus)).toFixed(2) }}元，其构成如下：</div>
            <div style="width: 50%;height: 300px;margin: auto;" ref="chart2" id="chart2"></div>
            <div class="marStyle">平均度电成本为 {{ compareData.comparePercent1 }}元/度，其变化曲线如下：</div>
            <div style="width: 70%;height: 300px;margin: auto;" ref="chart6" id="chart6"></div>
          </div>
          <!-- 1.2. 能耗及发电总量分析 -->
          <div class="cont_z">
            <div class="msrStr">2. 能耗及发电总量分析 </div>
            <el-table class="app-table" :data="tableData1" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="" align="left" prop="namecode">
              </el-table-column>
              <el-table-column label="本期" align="center" prop="name">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="type">
              </el-table-column>
              <el-table-column label="同级别排名" align="center" prop="site">
              </el-table-column>
            </el-table>
            <div class="marStyle">总能耗按发电源构成如下：</div>
            <div style="width: 50%;height: 300px;margin: auto;" ref="chart3" id="chart3"></div>
            <div class="marStyle">总能耗按电能分项消耗构成：</div>
            <div style="width: 50%;height: 300px;margin: auto;" ref="chart7" id="chart7"></div>
            <div class="marStyle">报告期内，新能源消纳率变化曲线如下：</div>
            <div style="width: 70%;height: 300px;margin: auto;" ref="chart4" id="chart4"></div>
          </div>
          <!-- 1.3. 能耗峰谷平分析 -->
          <div class="cont_z">
            <div class="msrStr">3. 能耗峰谷平分析</div>
            <el-table class="app-table" :data="tableData2" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="" align="left" prop="name" width="300">
              </el-table-column>
              <el-table-column label="本期" align="center" prop="valuecode">
              </el-table-column>
              <el-table-column label="比例" align="center" prop="type">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="site">
              </el-table-column>
            </el-table>
          </div>
          <!-- 1.4. 最大需量分析 -->
          <div class="cont_z">
            <div class="msrStr">4. 最大需量分析</div>
            <el-table class="app-table" :data="tableData3" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="" align="left" prop="namecode" width="300">
              </el-table-column>
              <el-table-column label="本期" align="center" prop="name">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="type">
              </el-table-column>
              <el-table-column label="同级别排名" align="center" prop="site">
              </el-table-column>
            </el-table>
          </div>
          <!-- 1.5. 平均功率因数分析 -->
          <div class="cont_z">
            <div class="msrStr">5. 平均功率因数分析</div>
            <el-table class="app-table" :data="tableData4" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="" align="left" prop="namecode" width="300">
              </el-table-column>
              <el-table-column label="本期" align="center" prop="name">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="type">
              </el-table-column>
              <el-table-column label="同级别排名" align="center" prop="site">
              </el-table-column>
            </el-table>
          </div>
          <!-- 1.6. 能源质量分析 -->
          <div class="cont_z">
            <div class="msrStr">6. 能源质量分析</div>
            <el-table class="app-table" :data="tableData5" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="" align="left" prop="namecode" width="300">
              </el-table-column>
              <el-table-column label="本期" align="center" prop="name">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="type">
              </el-table-column>
              <el-table-column label="同级别排名" align="center" prop="site">
              </el-table-column>
            </el-table>
            <div style="width: 50%;height: 300px;margin: auto;" ref="chart5" id="chart5"></div>
          </div>
        </div>
        <!--  -->
        <div class="pdf-header"
          style="font-weight: bold; padding:15px 8px; width: 100%; color: rgba(0, 0, 0, 0.85); position: fixed; top: -100vh;">
          <!-- 页头 -->
        </div>
        <div class="pdf-footer"
          style="font-weight: bold; padding: 15px 8px; width: 100%; border-top: 1px solid rgba(0, 0, 0, 0.85); position: fixed; top: -100vh;">
          <div style="display: flex; justify-content: center; align-items: center; padding-top: 5px;">
            页尾
          </div>
          <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">
            第<div class="pdf-footer-page"></div>页 / 共<div class="pdf-footer-page-count"></div>页
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headTitle from './homePage'
import directory from './directory'
import PdfLoader from './html2pdf.js'
import { outputPDF } from './outputPDF'
var echarts = require('echarts');
import "echarts/theme/macarons.js";
export default {
  components: { headTitle, directory },
  data() {
    return {
      nameList: [
        {
          name: '能源报告',
          page: 2
        },
        {
          name: '总成本及收益分析',
          page: 2
        },
        {
          name: '能耗及发电总量分析',
          page: 3
        },
        {
          name: '能耗峰谷平分析',
          page: 3
        },
        {
          name: '最大需量分析',
          page: 4
        },
        {
          name: '平均功率因数分析',
          page: 4
        },
        {
          name: '能源质量分析',
          page: 4
        }
      ],
      objAll: {},
      chartData: {},
      selfData: {},
      costData: {},
      phoTotData: {},
      totalData: {},
      codeData: {},
      pennegData: {},
      compareData: {},
      tableData: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      tableData4: [],
      tableData5: [],
    }
  },
  created() {
    let allQuery = this.$route.query
    this.objAll = {
      title: allQuery.regionName + allQuery.region2 + '系统',
      times: this.$moment(allQuery.date1).format('YYYY [年] ') + (allQuery.region3 == '天' ? this.$moment(allQuery.date1).format("MMM Do") : (allQuery.region3 == '月' ? (this.$moment(allQuery.date1).format("MMM [月]") + '度') : (allQuery.region3 + '度'))) + allQuery.region1,
      deptCode: allQuery.region,
      startDate: allQuery.region3 == '年' ? (this.$moment(allQuery.date1).startOf("year").format('YYYY-MM-DD HH:mm:ss')) : (this.$moment(allQuery.date1).startOf("month").format('YYYY-MM-DD HH:mm:ss')),
      endDate: allQuery.region3 == '年' ? (this.$moment(allQuery.date1).endOf("year").format('YYYY-MM-DD HH:mm:ss')) : (this.$moment(allQuery.date1).endOf("month").format('YYYY-MM-DD HH:mm:ss')),
      queryType: 'month',
    }
    if (allQuery.region3 == '月') {
      this.objAll["queryType"] = "month"
    } else if (allQuery.region3 == '年') {
      this.objAll["queryType"] = "year"
    } else if (allQuery.region3 == '天') {
      this.objAll["queryType"] = "day1h"
      this.objAll.startDate = this.$moment(allQuery.date1).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.objAll.endDate = this.$moment(allQuery.date1).endOf('day').format('YYYY-MM-DD HH:mm:ss')
    }
    this.getCouts()
    this.getAlldata();
    this.getAlldata1()
    this.getAlldata7()
    this.getStructure()
    this.getCharging()
    this.getabsorption()
    this.getAlldata2()
    this.getAlldata3()
    this.getAlldata4()
    this.getAlldata5()
    this.getDegree()
    this.getCircuit()
  },
  mounted() {
    // const xxx = document.getElementById('class_pdf')  // 需要导出部分页面的id名
    // this.pdfDownLoader = new PdfLoader(xxx, 'fileName', 'question-table')  // fileName -->导出文件名,  question-table -->防止被截断的class名
  },
  methods: {
    handleOutput() {
      let _this = this;
      const element = document.querySelector('.pdf-panel');
      const header = document.querySelector('.pdf-header');
      const footer = document.querySelector('.pdf-footer');
      outputPDF({
        element: element,
        footer: footer,
        header: header,
        contentWidth: 500,
        filename: '能源报告',
        _this: _this,
        dev_url: Config.dev_url
      })
    },
    //上传
    // uploadreport(files) {

    //   var formData = new FormData();
    //   formData.append("fileList", this.pdfDownLoader.mypdf);
    //   formData.append("appkey", "WORKORDER");
    //   formData.append("recordid", "1635320176178049026");
    //   var config = {
    //     //添加请求头
    //     headers: { "Content-Type": "multipart/form-data", },
    //     transformResponse: [response => JSON.parse(response.replace(/("fileid":)(\d{0,})(,)/g, '$1' + '"' + '$2' + '"' + '$3'))],
    //     // transformResponse: [response => JSON.parse(response.replace(/(?:"fileid":)(\d{0,})/g, '"$1"'))],
    //   }
    //   const url = Config.dev_url + "/api-file/file/uploads";
    //   this.$axios.post(url, formData, config,).then((res) => {
    //     if (res.data.code == 0) {
    //       // let aa = res.data.data[0].fileid.replace(/("fileid":)(\d{0,})(,)/g, '$1'+'"'+'$2'+'"'+'$3')
    //       // console.log(aa)
    //       this.$message.success(res.data.msg)
    //       let obj = {}
    //       console.log(res)


    //       this.$axios({
    //         url: Config.dev_url + "/api-apps/app/STATISTICALREPORTFILEINFO",
    //         method: "post",
    //         data: {
    //           REPORTORG: this.$route.query.regionName,
    //           TIMEAROUND: this.$route.query.region3,
    //           FILEID: res.data.data[0].fileid,
    //           FILENAME: '能源报告'

    //         }
    //       }).then(res => {
    //         alert('上传成功')
    //       })

    //     } else {
    //       // this.$message.warning(res.data.msg)
    //     }
    //   })

    // },
    initEchart() {
      let chartInit = echarts.init(this.$refs.chart1, "chart1");
      var getsjjg = []
      var getsjjgrs = this.chartData.seriesData;
      var folddata = [0]
      var syjgdata = [{
        name: 'Placeholder',
        type: 'bar',
        stack: 'Total',
        silent: true,
        itemStyle: {
          borderColor: 'transparent',
          color: 'transparent'
        },
        emphasis: {
          itemStyle: {
            borderColor: 'transparent',
            color: 'transparent'
          }
        },
        data: folddata
      }]
      var coust = 0
      getsjjgrs.forEach((item, index) => {
        coust += this.sumList(item.value)
        folddata.push(coust)
        getsjjg.push(item.name)
        syjgdata.push({
          name: item.name,
          type: 'bar',
          stack: 'Total',
          name: item.name,
          label: {
            show: true,
            position: 'top'
          },
          data: [index ? '-' : this.sumList(item.value), index == 1 ? this.sumList(item.value) : '-', index == 2 ? this.sumList(item.value) : '-']
        })
      });
      var option13 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let tar;
            if (params[1] && params[1].value !== '-') {
              tar = params[1];
            } else {
              tar = params[2];
            }
            return tar && tar.name + '<br/>' + tar.seriesName + ' : ' + tar.value;
          }
        },
        legend: {
          data: getsjjg
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: getsjjg
        },
        yAxis: {
          type: 'value'
        },
        series: syjgdata
      };

      chartInit.setOption(option13);
    },
    initEchart1() {
      let chartInit = echarts.init(this.$refs.chart2, "chart2");
      var getsjjg = this.selfData.seriesData;
      var colorList = ["#2D76FE", "#4DB8FF", "#6E93FF", "#5FEBFF", "#8AF1FF", "#8AF1FF", "#FFB200", "#3E6DFF", "#536EBF"];
      var syjgdata = [];
      getsjjg.forEach((item) => {
        syjgdata.push({
          name: item.name,
          value: this.sumList(item.value)
        })
      });
      var option13 = {
        backgroundColor: 'white',
        legend: {
          left: "center",
          bottom: '5%',
        },
        grid: {
          left: "20%",
        },
        series: [{
          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex]
              },
              borderColor: '#172659',
              borderWidth: 0
            }
          },
          type: 'pie',
          center: ["50%", "40%"],
          labelLine: {
            normal: {
              lineStyle: {
                color: '#34569D'
              }
            }
          },
          label: {
            normal: {
              show: true,
              formatter: params => {
                return params.percent.toFixed(0) + '%';
              },
              position: 'inner',
            }
          },
          data: syjgdata
        }]
      }

      chartInit.setOption(option13);
    },
    initEchart6() {
      let chartInit = echarts.init(this.$refs.chart6, "chart6");
      var getsjjg = this.compareData.seriesData
      var syjgdata = [];
      var xCharts = this.compareData.categoryData
      getsjjg.forEach((item) => {
        if (item.name == '当月') {
          syjgdata.push({
            name: item.name + '度电成本',
            data: item.value,
            type: 'line'
          })
        }
      });
      var option13 = {
        backgroundColor: 'white',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
        },
        xAxis: {
          type: 'category',
          data: xCharts
        },
        yAxis: {
          type: 'value'
        },
        legend: {
          left: "center",
          bottom: '5%',
        },
        series: syjgdata
      }

      chartInit.setOption(option13);
    },
    initEchart7() {
      let chartInit = echarts.init(this.$refs.chart7, "char7");
      var getsjjg = this.pennegData.seriesData;
      var colorList = ["#2D76FE", "#4DB8FF", "#6E93FF", "#5FEBFF", "#8AF1FF", "#8AF1FF", "#FFB200", "#3E6DFF", "#536EBF"];
      var syjgdata = [];
      getsjjg.forEach((item) => {
        syjgdata.push({
          name: item.name,
          value: this.sumList(item.value)
        })
      });
      var option13 = {
        backgroundColor: 'white',
        legend: {
          left: "center",
          bottom: '5%',
        },
        grid: {
          left: "20%",
        },
        series: [{
          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex]
              },
              borderColor: '#172659',
              borderWidth: 0
            }
          },
          type: 'pie',
          center: ["50%", "40%"],
          labelLine: {
            normal: {
              lineStyle: {
                color: '#34569D'
              }
            }
          },
          label: {
            normal: {
              show: true,
              formatter: params => {
                return params.percent.toFixed(0) + '%';
              },
              position: 'inner',
            }
          },
          data: syjgdata
        }]
      }

      chartInit.setOption(option13);
    },
    initEchart2() {
      let chartInit = echarts.init(this.$refs.chart3, "chart3");
      var getsjjg = this.totalData.seriesData;
      var colorList = ["#2D76FE", "#4DB8FF", "#6E93FF", "#5FEBFF", "#8AF1FF", "#8AF1FF", "#FFB200", "#3E6DFF", "#536EBF"];
      var syjgdata = [];
      getsjjg.forEach((item) => {
        syjgdata.push({
          name: item.type,
          value: this.sumList(item.count)
        })
      });
      var option13 = {
        backgroundColor: 'white',
        legend: {
          left: "center",
          bottom: '5%',
        },
        grid: {
          left: "20%",
        },
        series: [{
          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex]
              },
              borderColor: '#172659',
              borderWidth: 0
            }
          },
          type: 'pie',
          center: ["50%", "40%"],
          labelLine: {
            normal: {
              lineStyle: {
                color: '#34569D'
              }
            }
          },
          label: {
            normal: {
              show: true,
              formatter: params => {
                return params.percent.toFixed(0) + '%';
              },
              position: 'inner',
            }
          },
          data: syjgdata
        }]
      }

      chartInit.setOption(option13);
    },
    initEchart3() {
      let chartInit = echarts.init(this.$refs.chart4, "chart4");
      var getsjjg = this.phoTotData.seriesData;
      // var colorList = ["#2D76FE", "#4DB8FF", "#6E93FF", "#5FEBFF", "#8AF1FF", "#8AF1FF", "#FFB200", "#3E6DFF", "#536EBF"];
      var syjgdata = [];
      var xCharts = this.phoTotData.xCharts
      getsjjg.forEach((item) => {
        syjgdata.push({
          name: item.name,
          data: item.value,
          type: 'line'
        })
      });
      var option13 = {
        backgroundColor: 'white',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            var tar = params[1];
            return tar.name + '<br/>' + tar.seriesName + ' : ' + tar.value;
          }
        },
        xAxis: {
          type: 'category',
          data: xCharts
        },
        yAxis: {
          type: 'value'
        },
        legend: {
          left: "center",
          bottom: '5%',
        },
        series: syjgdata
      }

      chartInit.setOption(option13);
    },
    initEchart4() {
      let chartInit = echarts.init(this.$refs.chart5, "chart5");
      var getsjjg = this.codeData.seriesData//['频率不合格', '电压不合格', '三相不平衡', '电压谐波率'];
      var colorList = ["#2D76FE", "#4DB8FF", "#6E93FF", "#5FEBFF", "#8AF1FF", "#8AF1FF", "#FFB200", "#3E6DFF", "#536EBF"];
      var syjgdata = [];
      getsjjg.forEach((item) => {
        syjgdata.push({
          name: item.name,
          value: this.sumList(item.value)
        })
      });
      var option13 = {
        backgroundColor: 'white',
        legend: {
          left: "center",
          bottom: '5%',
        },
        grid: {
          left: "20%",
        },
        series: [{
          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex]
              },
              borderColor: '#172659',
              borderWidth: 0
            }
          },
          type: 'pie',
          center: ["50%", "40%"],
          labelLine: {
            normal: {
              lineStyle: {
                color: '#34569D'
              }
            }
          },
          label: {
            normal: {
              show: true,
              formatter: params => {
                return params.percent.toFixed(0) + '%';
              },
              position: 'inner',
            }
          },
          data: syjgdata
        }]
      }

      chartInit.setOption(option13);
    },
    // handleExport() {
    //   this.pdfDownLoader.outPutPdfFn('能源报告')
    //   let vm = this
    //   setTimeout(() => {
    //     vm.uploadreport()
    //   }, 10000);
    // },
    getCircuit() {
      let data = { "equipment": this.objAll.deptCode, "startTime": this.objAll.startDate, "endTime": this.objAll.endDate }
      let url = Config.dev_url + "/api-apps-v2/api/v2/circuit/circuit_analysis";
      this.$axios.post(url, data).then((res) => {
        let degree = res.data.data['功率因数'];
        console.log(degree, this.sumList(degree))
        this.tableData4.push({
          namecode: '平均功率因数',
          name: this.sumList(degree),
          type: '',
          site: ''
        })
      });
    },
    getDegree() {
      let data = { "deptCode": this.objAll.deptCode, "startTime": this.objAll.startDate, "endTime": this.objAll.endDate, "queryType": this.objAll.queryType=="day1h"?"day15m": this.objAll.queryType}
      let url = Config.dev_url + "/api-apps-v2/api/v2/electric/degree_analysis";
      this.$axios.post(url, data).then((res) => {
        let degree = res.data.data;
        this.tableData2.push({
          name: '尖峰能耗（万度）',
          valuecode: Number(degree.tip).toFixed(2),
          type: '',
          site: ''
        },
          {
            name: '峰时能耗（万度）',
            valuecode: Number(degree.peak).toFixed(2),
            type: '',
            site: ''
          },
          {
            name: '平时能耗（万度）',
            valuecode: Number(degree.level).toFixed(2),
            type: '',
            site: ''
          },
          {
            name: '谷时能耗（万度）',
            valuecode: Number(degree.valley).toFixed(2),
            type: '',
            site: ''
          })
      });
    },
    getCouts() {
      let data = { "deptCode": this.objAll.deptCode, "equipment": this.objAll.deptCode, "startTime": this.objAll.startDate, "endTime": this.objAll.endDate, "queryType": this.objAll.queryType, "type": 0 }
      let url = Config.dev_url + "/api-apps-v2/api/v2/energy/total";
      this.$axios.post(url, data).then((res) => {
        let counts = res.data.data;
        this.tableData1.push({
          namecode: '总能耗（万度）',
          name: counts['totalPower'],
          type: '',
          site: ''
        })
      });
    },
    getAlldata() {
      let data = { "deptCode": this.objAll.deptCode, "startTime": this.objAll.startDate, "endTime": this.objAll.endDate, "queryType": this.objAll.queryType };
      let url = Config.dev_url + "/api-apps-v2/api/v2/electric/analysis";
      this.$axios.post(url, data).then((res) => {
        this.chartData = res.data.data;
        this.tableData.push({
          name: '能源成本（万元）',
          codeValue: (this.sumNaN(res.data.data.cost) + this.sumNaN(res.data.data.avgPowerFactor) + this.sumNaN(res.data.data.baseCost)).toFixed(2),
          type: '',
          site: ''
        })
        this.initEchart()
      });
    },
    getAlldata1() {
      let data = { "deptCode": this.objAll.deptCode, "startTime": this.objAll.startDate, "endTime": this.objAll.endDate, "queryType": this.objAll.queryType };
      let url = Config.dev_url + "/api-apps-v2/api/v2/electric/income";
      this.$axios.post(url, data).then((res) => {
        this.selfData = res.data.data;
        this.tableData.push({
          name: '综合能源收益（万元）',
          codeValue: (this.sumNaN(res.data.data.self) + this.sumNaN(res.data.data.storage) + this.sumNaN(res.data.data.surplus)).toFixed(2),
          type: '',
          site: ''
        })
        this.initEchart1()
      });
    },
    getAlldata5() {
      let data = { "deptCode": this.objAll.deptCode, "startTime": this.objAll.startDate, "endTime": this.objAll.endDate, "queryType": this.objAll.queryType }
      let url = Config.dev_url + "/api-apps-v2/api/v2/electric/price";
      this.$axios.post(url, data).then((res) => {
        this.compareData = res.data.data;
        this.tableData.push({
          name: '度电成本（元/度）',
          codeValue: res.data.data.comparePrecent1,
          type: '',
          site: ''
        })
        this.initEchart6()
      });
    },
    getAlldata7() {
      let data = { "deptCode": this.objAll.deptCode, "assetNumber": "", "startTime": this.objAll.startDate, "endTime": this.objAll.endDate, "queryType": this.objAll.queryType }
      let url = Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/generation/statistics";
      this.$axios.post(url, data).then((res) => {
        this.pennegData = res.data.data;
        this.tableData1.push({
          namecode: '光伏发电量（万度）',
          name: this.pennegData.total,
          type: '',
          site: ''
        })
        setTimeout(()=>{
          this.initEchart7()
        },200)
      });
    },
    getStructure() {
      let data = { "deptCode": this.objAll.deptCode }
      let url = Config.dev_url + "/api-apps-v2/api/v2/energy/structure";
      this.$axios.post(url, data).then((res) => {
        let structure = res.data.data;
        structure.forEach((item) => {
          if (item.name == '市电供电量') {
            this.tableData1.push({
              namecode: '市电消耗量（万度）',
              name: item.value,
              type: '',
              site: ''
            })
          }
        })
      });
    },
    getCharging() {
      let data = { "deptCode": this.objAll.deptCode, "equipment": this.objAll.deptCode, "startTime": this.objAll.startDate, "endTime": this.objAll.endDate }
      let url = Config.dev_url + "/api-apps-v2/api/v2/charging/ranking";
      this.$axios.post(url, data).then((res) => {
        let charge = res.data.data;
        let sumData = 0
        Object.keys(charge).forEach((item) => {
          sumData += Number(charge[item].value)
        })
        this.tableData1.push({
          namecode: '储能放电量（万度）',
          name: (sumData).toFixed(2),
          type: '',
          site: ''
        })
      });
    },
    getabsorption() {
      let data = { "deptCode": this.objAll.deptCode, "startTime": this.objAll.startDate, "endTime": this.objAll.endDate, queryType: this.objAll.queryType }
      let url = Config.dev_url + "/api-apps-v2/api/v2/energy/renewable/replacement-ratio";
      this.$axios.post(url, data).then((res) => {
        let absorption = res.data.data;
        this.tableData1.push({
          namecode: '新能源替代率',
          name: Number(absorption.totalRate).toFixed(2),
          type: '',
          site: ''
        })
      });
    },
    getAlldata2() {
      let data = { "machineStation": this.objAll.deptCode, "machineType": ["市电", "光伏", "空调", "电机", "动力"], "type": this.objAll.queryType, "startTime": this.objAll.startDate, "endTime": this.objAll.endDate }
      let url = Config.dev_url + "/api-apps-v2/api/v2/electric/subterm/statistics";
      this.$axios.post(url, data).then((res) => {
        this.totalData = res.data.data;
        let list = res.data.data.seriesData;
        list.forEach((item) => {
          // 市电", "光伏", "空调", "电机", "动力"
          if (item.type == '市电') {
            this.tableData1.push({
              namecode: '市电上网电量（万度）',
              name: this.sumList(item.count).toFixed(2),
              type: '',
              site: ''
            })
          }
        })
        this.initEchart2()
      });
    },
    getAlldata3() {
      let data = { "deptCode": this.objAll.deptCode, "startTime": this.objAll.startDate, "endTime": this.objAll.endDate, "queryType": this.objAll.queryType };
      let url = Config.dev_url + "/api-apps-v2/api/v2/energy/renewable/penetration-rate";
      this.$axios.post(url, data).then((res) => {
        this.phoTotData = res.data.data;
        this.tableData1.push({
          namecode: '新能源消纳率',
          name: this.phoTotData.totalRate,
          type: '',
          site: ''
        })
        this.initEchart3()
      });
    },
    getAlldata4() {
      let data = { "assetNumber": "122027087", "startDate": this.objAll.startDate, "endDate": this.objAll.endDate, "queryType": this.objAll.queryType }
      let url = Config.dev_url + "/api-apps-v2/api/v2/equipment/power/imbalance-rate";
      this.$axios.post(url, data).then((res) => {
        this.codeData = res.data.data;
        setTimeout(()=>{
          this.initEchart4()
        },200)
      });
    },
    sumNaN(sum) {
      return Number(sum == 'NaN' ? null : (sum == 'null' ? null : sum))
    },
    sumList(arr) {
      if (arr.length == 0) {
        return 0
      } else {
        return arr.reduce(function (prev, curr, idx, arr) {
          return Number(prev == 'null' ? null : prev) + Number(curr == 'null' ? null : curr);
        });
      }
    },
  }
}
</script>
<style lang="less">
.energyReport {
  background: #fff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

#maintablepage {
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
      width: calc(100% - 40px);
}

#maintablepage .maintablepage-content .maintablepage-header {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .maintablepage-header-name {
    font-size: 46px;
  }
}

#maintablepage .maintablepage-content {
  flex: auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.app-table .el-table__header thead tr th {
  padding: 0;
  height: 40px;
  background: #f2f3f5;
}
</style>
<style lang="less" scoped>
#maintablepage .maintablepage-content {
  overflow-y: auto !important;
}

.contant_O {
  overflow-y: scroll;
  position: relative;
}

.cont_z {
  padding: 10px 0;
  font-size: 42px;
}

.msrStr {
  margin: 20px 0;
}

.marStyle {
  margin: 20px;
}

/deep/.app-table .el-table__header thead tr th .cell {
  height: 50px;
  line-height: 50px;
  font-size: 30px;
}

/deep/.app-table .el-table__body tbody tr td .cell {
  height: 50px;
  line-height: 50px;
  font-size: 30px;
}
</style>